<template>
  <section class="create-polling-wrapper">
    <div id="scene-container-polling" ref="sceneContainerPolling"></div>
    <div class="container-filter w-100 pt-5 pb-3 d-flex">
      <div class="child-nav d-flex mt-4 ml-auto justify-content-end mr-5">
        <input
          type="text"
          class="form-control morphism w-25 mr-3"
          :placeholder="$store.state.createPollingCurrentSoal + '/' + maxSoal"
        />
        <select name="" id="time" class="form-control mr-3 w-75">
          <option value="">30 Seconds</option>
        </select>
      </div>
    </div>
    <div class="container-fluid">
      <div class="w-100">
        <div class="d-flex">
          <div class="container-question ml-auto">
            <div class="question w-100">
              <div class="question-text mb-2">
                <b-alert variant="danger" :show="$store.state.showAlertSoalCreatePolling" fade>
                  {{ $store.state.responseSoal }}   
                </b-alert>
                <div class="d-flex align-items-center">
                  <div class="mx-3">
                    <button v-if="$store.state.createPollingData.image_url == null || $store.state.createPollingData.image_url == ''" type="button" class="btn btn-outline-light" @click="$bvModal.show('modal-insert-img')"><b-icon icon="images"></b-icon></button>
                    <div v-else>
                      <img :src="$store.state.createPollingData.image_url" :alt="$store.state.createPollingData.image_url" @click="$bvModal.show('modal-insert-img')" class="img-question">
                      <div class="d-flex justify-content-center mt-2">
                        <button type="button" class="btn btn-sm btn-outline-light mr-2" @click="deleteImage"><b-icon icon="trash"></b-icon></button>
                        <button type="button" class="btn btn-sm btn-outline-light" @click="$bvModal.show('modal-insert-img')"><b-icon icon="pencil-square"></b-icon></button>
                      </div>
                    </div>
                  </div>
                  <el-tiptap
                    :extensions="extensions"
                    lang="en"
                    placeholder="Type in your question here ..."
                    v-model="$store.state.createPollingData.question"
                    @onUpdate="cekQuestionLenght"
                    :charCounterCount = 'false'
                  />
                </div>
                <p class="text-right mb-0 mr-2 text-white mt-2">Characters : {{ 120 - lengthQuestion($store.state.createPollingData.question) }}</p>
              </div>
              <div class="row choice-wrapper">
                <template v-if="$store.state.createPollingData.question_type == 'Multiple Choice'">
                  <div class="col-6 p-2">
                    <div class="choice p-2">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer1CreatePolling" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="dark"
                          v-model="$store.state.opsi1PollingCreate"
                          vs-value="1"
                          :disabled="$store.state.opsi2PollingCreate != null || $store.state.opsi3PollingCreate != null || $store.state.opsi4PollingCreate != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.createPollingData.option_1" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer1Length"/>
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer1($store.state.createPollingData.option_1) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="choice p-2">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer2CreatePolling" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="dark"
                          v-model="$store.state.opsi2PollingCreate"
                          vs-value="2"
                          :disabled="$store.state.opsi1PollingCreate != null || $store.state.opsi3PollingCreate != null || $store.state.opsi4PollingCreate != null"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.createPollingData.option_2" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer2Length"/>
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer2($store.state.createPollingData.option_2) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="choice p-2">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer3CreatePolling" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="dark"
                          v-model="$store.state.opsi3PollingCreate"
                          :disabled="$store.state.opsi1PollingCreate != null || $store.state.opsi2PollingCreate != null || $store.state.opsi4PollingCreate != null"
                          vs-value="3"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.createPollingData.option_3" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer3Length"/>
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer3($store.state.createPollingData.option_3) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="choice p-2">
                      <b-alert variant="danger" :show="$store.state.showAlertAnswer4CreatePolling" fade>
                        {{ $store.state.responseAnswer }}   
                      </b-alert>
                      <div class="position-relative">
                        <vs-checkbox
                          color="dark"
                          v-model="$store.state.opsi4PollingCreate"
                          :disabled="$store.state.opsi1PollingCreate != null || $store.state.opsi2PollingCreate != null || $store.state.opsi3PollingCreate != null"
                          vs-value="4"
                          class="position-absolute checkbox-choice"
                        ></vs-checkbox>
                        <el-tiptap :charCounterCount = 'false' v-model="$store.state.createPollingData.option_4" :extensions="extensions_choice_pg" lang="en" placeholder="Type in choices here .." @onUpdate="cekAnswer4Length" />
                        <p class="text-right mb-0 mr-2 text-white mt-3 mb-2">Characters : {{ 70 - lengthAnswer4($store.state.createPollingData.option_4) }}</p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="col-6 p-2">
                    <div class="choice p-2 position-relative">
                      <vs-checkbox
                        color="dark"
                        v-model="$store.state.opsi1PollingCreate"
                        vs-value="1"
                        class="position-absolute checkbox-choice"
                      ></vs-checkbox>
                      <el-tiptap :readonly="true" v-model="$store.state.createPollingData.option_1" :extensions="extensions_choice_tf" lang="en" />
                    </div>
                  </div>
                  <div class="col-6 p-2">
                    <div class="choice p-2 position-relative">
                      <vs-checkbox
                        color="dark"
                        v-model="$store.state.opsi2PollingCreate"
                        vs-value="2"
                        class="position-absolute checkbox-choice"
                      ></vs-checkbox>
                      <el-tiptap :readonly="true" v-model="$store.state.createPollingData.option_2" :extensions="extensions_choice_tf" lang="en" />
                    </div>
                  </div>
                </template>
              </div>
                <div v-if="$store.state.showAlertSoalCreatePolling == false && $store.state.showAlertAnswer1CreatePolling == false && $store.state.showAlertAnswer2CreatePolling == false && $store.state.showAlertAnswer3CreatePolling == false && $store.state.showAlertAnswer4CreatePolling == false" class="d-flex justify-content-end mb-5">
                  <div v-if="$store.state.createPollingCurrentSoal == 10">
                    <!-- PREV BUTTON -->
                    <button v-if="$store.state.createPollingCurrentSoal == 1 || $store.state.createPollingCurrentSoal == 0 " style="cursor : not-allowed" class="btn btn-light mr-2 disabled"><b-icon icon="chevron-left"></b-icon></button>
                    <button v-else class="btn btn-light mr-2" @click="renderSoal($store.state.createPollingCurrentSoal -1)"><b-icon icon="chevron-left"></b-icon></button>
                    <!-- NEXT BUTTON -->
                    <button class="btn btn-light" @click="$bvModal.show('subs-modal')"><b-icon icon="chevron-right"></b-icon></button>
                 
                  </div>
                  <div v-else>
                    <!-- PREV BUTTON -->
                    <button v-if="$store.state.createPollingCurrentSoal == 1 || $store.state.createPollingCurrentSoal == 0 " style="cursor : not-allowed" class="btn btn-light mr-2 disabled"><b-icon icon="chevron-left"></b-icon></button>
                    <button v-else class="btn btn-light mr-2" @click="renderSoal($store.state.createPollingCurrentSoal -1)"><b-icon icon="chevron-left"></b-icon></button>

                    <!-- NEXXT BUTTON -->
                    <button v-if="$store.state.listCreatePollingQuestion !=null && $store.state.createPollingCurrentSoal < $store.state.listCreatePollingQuestion.length" class="btn btn-light" @click="renderSoal($store.state.createPollingCurrentSoal+1)"><b-icon icon="chevron-right"></b-icon></button>
                    <button v-else-if="$store.state.createPollingCurrentSoal == 10 " style="cursor : not-allowed" class="btn btn-light disabled"><b-icon icon="chevron-right"></b-icon></button>
                    <button v-else-if="$store.state.listCreatePollingQuestion !=null && $store.state.createPollingCurrentSoal >= $store.state.listCreatePollingQuestion.length " class="btn btn-light" @click="addIndex()"><b-icon icon="chevron-right"></b-icon></button>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-insert-img"
      ref="modal"
      centered 
      @ok="saveImage"
    >
      <form ref="form">
        <b-form-group
          label="Input image URL"
          label-for="image-input"
        >
          <b-form-input
            id="image-input"
            v-model="image_url"
            required
            type="url"
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

    <!-- <div class="question">
            <div class="question-text mb-2">
                <el-tiptap :extensions="extensions" lang="en" placeholder="Type in your question here ..."/>
            </div>
            <div class="row choice-wrapper">
                <template v-if="tipeQuiz == 'pg'"> 
                    <div class="col-6 p-2">
                        <div class="choice p-2 position-relative">
                            <vs-checkbox color="dark" v-model="opsi1" class="position-absolute checkbox-choice"></vs-checkbox>
                            <el-tiptap :extensions="extensions_choice_pg" lang="en"/>
                        </div>
                    </div>
                    <div class="col-6 p-2">
                        <div class="choice p-2 position-relative">
                            <vs-checkbox color="dark" v-model="opsi2" class="position-absolute checkbox-choice"></vs-checkbox>
                            <el-tiptap :extensions="extensions_choice_pg" lang="en"/>
                        </div>
                    </div>
                    <div class="col-6 p-2">
                        <div class="choice p-2 position-relative">
                            <vs-checkbox color="dark" v-model="opsi3" class="position-absolute checkbox-choice"></vs-checkbox>
                            <el-tiptap :extensions="extensions_choice_pg" lang="en"/>
                        </div>
                    </div>
                    <div class="col-6 p-2">
                    <div class="choice p-2 position-relative">
                            <vs-checkbox color="dark" v-model="opsi4" class="position-absolute checkbox-choice"></vs-checkbox>
                            <el-tiptap :extensions="extensions_choice_pg" lang="en"/>
                        </div>
                    </div>
                </template>
                <template v-else> 
                    <div class="col-6 p-2">
                        <div class="choice p-2 position-relative">
                            <vs-checkbox color="dark" v-model="opsi1" class="position-absolute checkbox-choice"></vs-checkbox>
                            <el-tiptap :extensions="extensions_choice_tf" lang="en"/>
                        </div>
                    </div>
                    <div class="col-6 p-2">
                        <div class="choice p-2 position-relative">
                            <vs-checkbox color="dark" v-model="opsi2" class="position-absolute checkbox-choice"></vs-checkbox>
                            <el-tiptap :extensions="extensions_choice_tf" lang="en"/>
                        </div>
                    </div>
                </template>
            </div>
        </div> -->
  </section>
</template>
<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Stats from "stats.js";
import { ElementTiptap } from "element-tiptap";
import Swal from 'sweetalert2';

// import { addQuestions } from '@/services/question/question.service'
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Image,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  Link,
} from "element-tiptap";
import "element-ui/lib/theme-chalk/index.css";
import "element-tiptap/lib/index.css";
import $ from "jquery";

export default {
  components: {
    "el-tiptap": ElementTiptap,
  },
  data() {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        // new Image(),
        new Table({ resizable: true }),
        new TableHeader(),
        new TableRow(),
        new TableCell(),
        new Link(),
      ],
      extensions_choice_pg: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Image(),
      ],
      extensions_choice_tf: [new Doc(), new Text(), new Paragraph(), new Image()],
      container: null,
      scene: null,
      camera: null,
      controls: null,
      renderer: null,
      stats: null,
      mixers: [],
      clock: null,
      opsi1: null,
      opsi2: null,
      opsi3: null,
      opsi4: null,
      tipeQuiz: "pg",
      additional : false,
      listQuestion:[],
      newListQuestion:[],
      newQuestion : [],
      cobainput : null,
      formData: {
        quiz_id : '',
        question : '',
        option_1 : '',
        option_2 : '',
        option_3 : '',
        option_4 : '',
        correct_answer : '',
        index : 1
      },
      listNewQuestion :[],
      maxSoal : 10,
      currentSoal : 1,
      viewSoal : 0,
      image_url : '',
    };
  },
  methods: {
    init() {
      // set container
      this.container = this.$refs.sceneContainerPolling;

      // add stats
      this.stats = new Stats();
      this.clock = new THREE.Clock();
      this.container.appendChild(this.stats.dom);

      // add camera
      const fov = 5.5; // Field of view
      const aspect = this.container.clientWidth / this.container.clientHeight;
      const near = 0.1; // the near clipping plane
      const far = 1000; // the far clipping plane
      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, 2, 12);
      this.camera = camera;

      // create scene
      this.scene = new THREE.Scene();

      // add lights
      const ambientLight = new THREE.HemisphereLight(
        0xffffff, // bright sky color
        0x222222, // dim ground color
        1 // intensity
      );
      const mainLight = new THREE.DirectionalLight(0xffffff, 4.0);
      mainLight.position.set(10, 10, 10);
      this.scene.add(ambientLight, mainLight);

      // add controls
      this.controls = new OrbitControls(this.camera, this.container);
      // this.controls.enabled = false
      this.controls.minPolarAngle = this.controls.maxPolarAngle = Math.PI * 0.45;
      this.controls.enableZoom = false; //To enable zoom mode on 3d

      // create renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.gammaFactor = 2.2;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.physicallyCorrectLights = true;
      this.container.appendChild(this.renderer.domElement);

      // set aspect ratio to match the new browser window aspect ratio
      this.camera.aspect =
        this.container.clientWidth / this.container.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );

      //load model

      const loader = new GLTFLoader();

      const onLoad = (result, position) => {
        const model = result.scene.children[0];
        model.position.copy(position);
        model.scale.set(0.5, 0.5, 0.5);

        const mixer = new THREE.AnimationMixer(model);
        this.mixers.push(mixer);

        const animation = result.animations[0];
        const action = mixer.clipAction(animation);
        action.play();

        this.scene.add(model);
      };

      const parrotPosition = new THREE.Vector3(0, -0.55, 0);
      loader.load(
        "/three-assets/scene.glb",
        (gltf) => onLoad(gltf, parrotPosition),
        undefined,
        undefined
      );

      this.renderer.setAnimationLoop(() => {
        this.update();
        this.render();
      });
    },
    update() {
      const delta = this.clock.getDelta();
      this.mixers.forEach((mixer) => mixer.update(delta));
    },
    render() {
      // this.renderer.setSize(window.screen.width, window.screen.height)
      this.renderer.render(this.scene, this.camera);

    },
    setSideBar() {
      this.$store.commit("IS_SIDEBAR_ACTIVE", false);
    },
    addIndex(){
      var num = Math.floor(Math.random()*90000) + 10000;//random number for dummy id


      if(this.$store.state.createPollingData.question_type == 'True or False'){
        this.$store.state.createPollingData.option_3 = null
        this.$store.state.createPollingData.option_4 = null
      }

      if(this.$store.state.opsi1PollingCreate != null){
        this.$store.state.createPollingData.correct_answer = "1" 
      } else if(this.$store.state.opsi2PollingCreate != null){
        this.$store.state.createPollingData.correct_answer = "2" 
      } else if(this.$store.state.opsi3PollingCreate != null){
        this.$store.state.createPollingData.correct_answer = "3" 
      } else if(this.$store.state.opsi4PollingCreate != null){
        this.$store.state.createPollingData.correct_answer = "4" 
      } else {
        this.$store.state.createPollingData.correct_answer = null;
      }

      this.$store.state.listCreatePollingQuestion = this.$store.state.listCreatePollingQuestion || [];
      
      if(this.$store.state.createPollingData._id != null){ 
        const updateQ = this.$store.state.listCreatePollingQuestion.find(e => e._id === this.$store.state.createPollingData._id)
        localStorage.setItem("listQuestionCreatePolling", JSON.stringify(updateQ));
        console.log("Masuk Sini")
      } else {
        if(this.$store.state.createPollingData.questions!= null || this.$store.state.createPollingData.question != ''){

          this.$store.state.createPollingData._id = num //set dummy id for edit on local storage
      
          this.$store.state.listCreatePollingQuestion.push(this.$store.state.createPollingData)
        //   this.$store.state.newlistCreatePollingQuestion.push(this.$store.state.createPollingData)

          this.newQuestion.push(this.$store.state.createPollingData)

          console.log(this.$store.state.createPollingData.index)

          console.log("Masuk sini Juga")

          localStorage.setItem("listQuestionCreatePolling", JSON.stringify(this.$store.state.listCreatePollingQuestion));
          localStorage.setItem("questionNewPolling", JSON.stringify(this.newQuestion));

          // localStorage.setItem("newListQuestionCreator", JSON.stringify(this.$store.state.newlistCreatePollingQuestion));
        }
      }

      const indexAlready = this.$store.state.listCreatePollingQuestion.find(element => element.index == this.$store.state.createPollingData.index) || null

      if(indexAlready != null){

        this.$store.state.createPollingCurrentSoal = this.$store.state.listCreatePollingQuestion.length
        this.$store.state.createPollingCurrentSoal += 1;
        
      } else {

        this.$store.state.createPollingCurrentSoal = this.$store.state.createPollingData.index + 1
      }

      this.$store.state.createPollingData = {
        quiz_id : '',
        question : '',
        option_1 : '',
        option_2 : '',
        option_3 : '',
        option_4 : '',
        correct_answer : '',
        question_type: 'Multiple Choice',
        index : this.$store.state.createPollingData.index+1
      }

      this.$store.state.opsi1PollingCreate = null
      this.$store.state.opsi2PollingCreate = null
      this.$store.state.opsi3PollingCreate = null
      this.$store.state.opsi4PollingCreate = null

      console.log("AFTER " + this.$store.state.createPollingData.index)
    },
   
    renderSoal(index){
      var num = Math.floor(Math.random()*90000) + 10000;//random number for dummy id

      
      let newForm = {
        quiz_id : '',
        question : '',
        option_1 : '',
        option_2 : '',
        option_3 : '',
        option_4 : '',
        correct_answer : '',
        question_type: 'Multiple Choice',
        index : this.$store.state.createPollingData.index+1
      }
      this.$store.state.createPollingCurrentSoal = index;
      console.log(this.$store.state.createPollingCurrentSoal)

      if(this.$store.state.createPollingData.question_type == 'True or False'){
        this.$store.state.createPollingData.option_3 = null
        this.$store.state.createPollingData.option_4 = null
      }

      if(this.$store.state.opsi1PollingCreate != null){
        this.$store.state.createPollingData.correct_answer = "1" 
      } else if(this.$store.state.opsi2PollingCreate != null){
        this.$store.state.createPollingData.correct_answer = "2" 
      } else if(this.$store.state.opsi3PollingCreate != null){
        this.$store.state.createPollingData.correct_answer = "3" 
      } else if(this.$store.state.opsi4PollingCreate != null){
        this.$store.state.createPollingData.correct_answer = "4" 
      } else {
        this.$store.state.createPollingData.correct_answer = null;
      }

      this.$store.state.listCreatePollingQuestion = this.$store.state.listCreatePollingQuestion || [];
      
      if(this.$store.state.createPollingData._id != null){ 
        const updateQ = this.$store.state.listCreatePollingQuestion.find(e => e._id === this.$store.state.createPollingData._id)
        localStorage.setItem("listQuestionCreatePolling", JSON.stringify(updateQ));
      } else {
        if(this.$store.state.createPollingData.questions!= null || this.$store.state.createPollingData.question != ''){

          this.$store.state.createPollingData._id = num //set dummy id for edit on local storage
      
          this.$store.state.listCreatePollingQuestion.push(this.$store.state.createPollingData)
        //   this.$store.state.newlistCreatePollingQuestion.push(this.$store.state.createPollingData)

          this.newQuestion.push(this.$store.state.createPollingData)

          console.log(this.$store.state.createPollingData.index)

          localStorage.setItem("listQuestionCreatePolling", JSON.stringify(this.$store.state.listCreatePollingQuestion));
          // localStorage.setItem("questionNewPolling", JSON.stringify(this.newQuestion));
          // localStorage.setItem("newListQuestionCreator", JSON.stringify(this.$store.state.newlistCreatePollingQuestion));
        }
      }

      this.$store.state.createPollingData = this.$store.state.listCreatePollingQuestion.find(element => element.index == index) || newForm

      if(this.$store.state.createPollingData.correct_answer == '1'){
        this.$store.state.opsi1PollingCreate = true
        this.$store.state.opsi2PollingCreate = null
        this.$store.state.opsi3PollingCreate = null
        this.$store.state.opsi4PollingCreate = null
      } else if(this.$store.state.createPollingData.correct_answer == '2'){
        this.$store.state.opsi1PollingCreate = null
        this.$store.state.opsi2PollingCreate = true
        this.$store.state.opsi3PollingCreate = null
        this.$store.state.opsi4PollingCreate = null
      } else if(this.$store.state.createPollingData.correct_answer == '3'){
        this.$store.state.opsi1PollingCreate = null
        this.$store.state.opsi2PollingCreate = null
        this.$store.state.opsi3PollingCreate = true
        this.$store.state.opsi4PollingCreate = null
      } else if(this.$store.state.createPollingData.correct_answer == '4'){
        this.$store.state.opsi1PollingCreate = null
        this.$store.state.opsi2PollingCreate = null
        this.$store.state.opsi3PollingCreate = null
        this.$store.state.opsi4PollingCreate = true
      } else {
        this.$store.state.opsi1PollingCreate = null
        this.$store.state.opsi2PollingCreate = null
        this.$store.state.opsi3PollingCreate = null
        this.$store.state.opsi4PollingCreate = null
      }

      console.log("REMDER index" + this.$store.state.listCreatePollingQuestion.length)

    },
    showAlert(){
      Swal.fire('Hanya bisa membuat 10 Soal !')
    },
    
    tipeQuestion(e){
      console.log(e.target.value)
      if(e.target.value == 'Multiple Choice'){
        this.$store.state.createPollingData.option_1 = ''
        this.$store.state.createPollingData.option_2 = ''
      } else {
        this.$store.state.createPollingData.option_1 = 'BENAR'
        this.$store.state.createPollingData.option_2 = 'SALAH'
      }
    },
    saveImage(){
      this.$store.state.createPollingData.image_url = this.image_url
      this.image_url = ''
    },
    deleteImage(){
      this.$store.state.createPollingData.image_url = ''
    },
    cekQuestionLenght(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 120) {
        this.$store.state.showAlertSoalCreatePolling = false
      } else {
        this.$store.state.showAlertSoalCreatePolling = true
      }
    },
    lengthQuestion(e){
      if(e != undefined){
        const value = e
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },
    cekAnswer1Length(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        this.$store.state.showAlertAnswer1CreatePolling = false
      } else {
        this.$store.state.showAlertAnswer1CreatePolling = true
      }
    },
    lengthAnswer1(e){
      if(e != undefined){
        const value = e
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },
    cekAnswer2Length(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        this.$store.state.showAlertAnswer2CreatePolling = false
      } else {
        this.$store.state.showAlertAnswer2CreatePolling = true
      }
    },
    lengthAnswer2(e){
      if(e != undefined){
        const value = e
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },
    cekAnswer3Length(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        this.$store.state.showAlertAnswer3CreatePolling = false
      } else {
        this.$store.state.showAlertAnswer3CreatePolling = true
      }
    },
    lengthAnswer3(e){
      if(e != undefined){
        const value = e
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },
    cekAnswer4Length(e){
      const value = e
      const regex = value.replace(/<\/?[^>]+>/ig, ""); 
      if (regex.length <= 70) {
        this.$store.state.showAlertAnswer4CreatePolling = false
      } else {
        this.$store.state.showAlertAnswer4CreatePolling = true
      }
    },
    lengthAnswer4(e){
      if(e != undefined){
        const value = e
        const regex = value.replace(/<\/?[^>]+>/ig, ""); 
        return regex.length
      } else {
        return 0
      }
    },
  },
  computed: {
    hasAdditional(){
      console.log(this.opsi1)
        return this.opsi1.length >0;
    },

  },
  mounted() {
    $("section.create-polling-wrapper").parent().addClass("m-0");

    this.setSideBar();
    this.init();
    // let data = localStorage.getItem("listQuestionCreator");
    let data = localStorage.getItem("listQuestionCreatePolling");
    if(data){
      this.$store.state.listCreatePollingQuestion = JSON.parse(data);
    } else {
      this.$store.state.listCreatePollingQuestion = []
    }
    let index = 1;
    this.$store.state.listCreatePollingQuestion.forEach((el) => {
      el.index = index
      index++;
    })
    this.$store.state.createPollingData.index = index

    var index_id = this.$route.query.index
    if(index_id != undefined){
      this.$store.state.createPollingCurrentSoal = index_id
      this.renderSoal(this.$store.state.createPollingCurrentSoal)
    } else {
      this.$store.state.createPollingCurrentSoal = index;
    }

    if(this.$store.state.createPollingData.correct_answer == '1'){
      this.$store.state.opsi1PollingCreate = true
      this.$store.state.opsi2PollingCreate = null
      this.$store.state.opsi3PollingCreate = null
      this.$store.state.opsi4PollingCreate = null
    } else if(this.$store.state.createPollingData.correct_answer == '2'){
      this.$store.state.opsi1PollingCreate = null
      this.$store.state.opsi2PollingCreate = true
      this.$store.state.opsi3PollingCreate = null
      this.$store.state.opsi4PollingCreate = null
    } else if(this.$store.state.createPollingData.correct_answer == '3'){
      this.$store.state.opsi1PollingCreate = null
      this.$store.state.opsi2PollingCreate = null
      this.$store.state.opsi3PollingCreate = true
      this.$store.state.opsi4PollingCreate = null
    } else if(this.$store.state.createPollingData.correct_answer == '4'){
      this.$store.state.opsi1PollingCreate = null
      this.$store.state.opsi2PollingCreate = null
      this.$store.state.opsi3PollingCreate = null
      this.$store.state.opsi4PollingCreate = true
    } else {
      this.$store.state.opsi1PollingCreate = null
      this.$store.state.opsi2PollingCreate = null
      this.$store.state.opsi3PollingCreate = null
      this.$store.state.opsi4PollingCreate = null
    }
  },
};
</script>

<style>
html {
  height: 100% !important;
}
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
.main-wrapper {
  height: 100% !important;
}
.main-container-fluid {
  /* height: 100% !important;
  margin-left: 0px !important;
  padding: 0 !important; */
}
.create-polling-wrapper {
  background-image: url("../../../assets/images/background/bg-space-web.png");
  height: auto;
  /* background-image: 100% auto; */
  width: 100%;
  background-size: cover;
}
#scene-container-polling {
  width: 120%;
  height: 114.8%;
  position: absolute;
  left: -40vw;
  top: 5vh;
}
#scene-container-polling div {
  display: none !important;
}
.container-filter {
  background: rgba(12, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 1px solid rgba(12, 0, 0, 0.3);
  position: relative;
}
.container-question {
  width: 67.5%;
}
.question {
  top: 0%;
  left: 30%;
  width: 70%;
  height: 100%;
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
.question-text {
  position: relative;
  padding: 15px;
  background: rgba(12, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 0px solid rgba(12, 0, 0, 0.3);
  border-radius: 20px;
}
.question-text:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -42px;
  top: 17px;
  /* border: 10px solid transparent;
    border-right: 32px solid rgb(193,193,193); /* IE8 Fallback */
  /* border-right: 32px solid rgba(193,193,193,0.5);  */
  z-index: 2;
}
.question-text:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -45px;
  top: 50px;
  border: 15px solid transparent;
  border-right: 30px solid rgba(12, 0, 0, 0.6);
  z-index: 3;
}

.choice {
  background: rgba(12, 0, 0, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 0px solid rgba(12, 0, 0, 0.3);
  border-radius: 20px;
}

.checkbox-choice {
  right: 5px;
  top: 18px;
}

.subNav {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 15%;
  top: 0%;
  z-index: 2;
}

.subNav-text {
  position: relative;
  padding: 65px;
  margin-bottom: 10px;
}

.subNav-text input::-webkit-input-placeholder {
  text-align: center;
}

.morphism {
  background: rgba(255, 255, 255, 0.03);
}

.w-custom {
  width: 15% !important;
}
.el-tiptap-editor__menu-bar {
  background: none !important;
  border: none !important;
}
.el-tiptap-editor__content {
  background: none !important;
  color: white !important;
}
.el-tiptap-editor__footer {
  background: none !important ;
  border: none !important;
}
.el-tiptap-editor__content {
  border: 1px solid #fff !important;
  border-radius: 5px;
}
.el-tiptap-editor__menu-bar:before {
  background: none !important;
}
.el-tiptap-editor__command-button {
  color: #fff !important;
}
</style>

<style scoped>
.child-nav input::-webkit-input-placeholder {
  text-align: center;
}

.img-question{
  width: 7rem;
  height: 7rem;
}
</style>
